import React from "react"
import { Link } from "gatsby"

const SupportAndCommunitySubMenu = () => {
  return (
    <>
      <li><Link data-gtm-event-category="Top Carousel" data-gtm-event-action="Click" data-gtm-event-label="Order Resources" activeClassName="isActive" partiallyActive={true} to="/support-and-community/order-resources/">Order Resources <span className="np-arrow">&rsaquo;</span></Link></li>
      <li><Link data-gtm-event-category="Top Carousel" data-gtm-event-action="Click" data-gtm-event-label="Downloadable Resources" activeClassName="isActive" partiallyActive={true} to="/support-and-community/downloadable-resources/">Downloadable Resources & Videos &rsaquo;</Link></li>
      <li><Link data-gtm-event-category="Top Carousel" data-gtm-event-action="Click" data-gtm-event-label="Neuroblastoma Support and Advocacy Groups" activeClassName="isActive" partiallyActive={true} to="/support-and-community/support-groups/">Neuroblastoma Support and Advocacy Groups <span className="np-arrow">&rsaquo;</span></Link></li>
      {/* <li><Link data-gtm-event-category="Top Carousel" data-gtm-event-action="Click" data-gtm-event-label="Skivolos Kid Zone" activeClassName="isActive" partiallyActive={true} to="/support-and-community/skivolos-kid-zone/">Skivolo's Kid Zone <span className="np-arrow">&rsaquo;</span></Link></li> */}
      <li><Link data-gtm-event-category="Top Carousel" data-gtm-event-action="Click" data-gtm-event-label="Glossary of Terms" activeClassName="isActive" partiallyActive={true} to="/support-and-community/glossary/">Glossary of Terms <span className="np-arrow">&rsaquo;</span></Link></li>
      <li><Link data-gtm-event-category="Top Carousel" data-gtm-event-action="Click" data-gtm-event-label="Psychological Effects of Childhood Cancer" activeClassName="isActive" partiallyActive={true} to="/support-and-community/mental-health/">Psychological Effects of Childhood Cancer <span className="np-arrow">&rsaquo;</span></Link></li>
      <li><Link data-gtm-event-category="Top Carousel" data-gtm-event-action="Click" data-gtm-event-label="Caring for the Caregiver" activeClassName="isActive" to="/support-and-community/caregiver-support/">Caring for the Caregiver <span className="np-arrow">&rsaquo;</span></Link></li>
    </>
  )
}

export default SupportAndCommunitySubMenu
