import React, {useState} from 'react';
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types"

const InteriorMenu = (props) => {

  const [navOpen, setNavOpen] = useState(false);

  const toggleInnerNav =(e)=>{
    if(!navOpen){
      setNavOpen(true);
    }else{
      setNavOpen(false);
    }
    e.preventDefault();
  }

  return (
    <>
      <nav className={navOpen ? `interior-menu isOpen` : `interior-menu`}>
        <div className="interior-menu__heading">
          <span className={navOpen ? `interior-menu__heading-icon reverse` : `interior-menu__heading-icon`}><FontAwesomeIcon icon={faAngleDown} /></span>
          <a aria-label={props.title + ' Pages In This Section'} href="/" onClick={(e) => toggleInnerNav(e)} dangerouslySetInnerHTML={{ __html: props.title + ` <br/> <span class="interior-menu__heading-sml">Pages in this section</span>` }}></a>
        </div>
        <ul className={navOpen ? `interior-menu__menu isVisible` : `interior-menu__menu`}>
          {props.children}
        </ul>
      </nav>
    </>
  );
}

InteriorMenu.defaultProps = {
  title: 'YOU FORGOT TO ADD A TITLE PROP',
};

InteriorMenu.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired
}

export default InteriorMenu
