import React from "react"
function AddToOrderButton({ id, disabled }) {
 
  // Button Starts in Disabled State
  let buttonText = "Add To Order"
  let buttonStyle = {
    backgroundColor: "#bb29bb",
    opacity: "50%",
  }
  

  // If Button Is Not Disabled change CSS & Text
  if (!disabled) {
    buttonText = 'Added To Order'
    buttonStyle = {
      backgroundColor: "#711471",
      opacity: "100%",
    }
  }
  
  return (
    <button
      id={id}
      name={id}
      className="btn--secondary"
      style={buttonStyle}
      type="button"
      disabled={disabled}
    >
      {buttonText}
    </button>
  )
}

export default AddToOrderButton
