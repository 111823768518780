import React from "react"
import PropTypes from "prop-types"
import FancyImage from "../global-components/fancy-image"

const InteriorPageHeader = (props) => {
  return (
    <section id={props.id} className="interior-page__header" style={{backgroundImage: `url(${props.backgroundImage})`, backgroundSize:`cover`, backgroundPosition: `center center`, backgroundRepeat: `none`}}>
      <div className={(props.supressImage)? `row row--inner align-bottom` : `row row--inner align-middle`}>
        <div className="columns">
          <div className={(props.supressImage)? `interior-page__headline interior-page__headline--no-image`: `interior-page__headline`}>
            {!props.supressImage &&
              <FancyImage url={props.roundImage} width="360" alt={props.alt}/>
            }
            {props.children}
          </div>
        </div>
      </div>
    </section>
  )
}

InteriorPageHeader.defaultProps = {
  alt: 'This is where you have alt text',
  roundImage: 'https://placehold.it/650x650?text=FPO+ADD+URL+PROP!'
};

InteriorPageHeader.propTypes = {
  children: PropTypes.node.isRequired,
  roundImage: PropTypes.node.isRequired,
  backgroundImage: PropTypes.node.isRequired,
  alt: PropTypes.string
}

export default InteriorPageHeader
